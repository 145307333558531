import React from "react"
import { graphql } from "gatsby"

import Seo from "~/components/seo"
import Container from "~/components/container"

const PolicyTemplate = ({
  data: { policy }
}) => {
  const { pageTitle, bodyText } = policy

  return (
    <>
      <Seo title={pageTitle} />

      <section>
        <Container>
          <div
            className="prose mx-auto"
            dangerouslySetInnerHTML={{ __html: bodyText.childMarkdownRemark.html }}
          />
        </Container>
      </section>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    policy: contentfulPagePolicyPage(handle: {eq: $handle }) {
      pageTitle
      bodyText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default PolicyTemplate
